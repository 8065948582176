
<template>
    <div>
        <div class="row">
            <div class="col my-auto">
                <h3>Donasi Anda</h3>
            </div>
        </div>

        <div class="mt-2">
            <div class="row" style="border-radius: 6px;" v-for="index in donations" :key="index.id" @click="goDetail(index.id)">
                <div class="col p-2">
                    <div class="row bg-white px-2 py-2">
                        <div class="col-auto">
                            <img style="border-radius: 6px; width: 60px; height: 60px; object-position: center;" :src="index.campaign_img" class="img-fluid" alt="">
                        </div>
                        <div class="col my-auto text-truncate">
                            <div>
                                <div>
                                    <h5 class="text-truncate">{{ index.campaign_title }}</h5>
                                </div>
                                <div class="text-muted">
                                    {{ index.location }}
                                </div>
                            </div>
                        </div>
                        <div class="col-auto my-auto">
                            <img style="width: 20px;" :src="checkListAsset" alt="" v-if="index.status == 'PAID'">
                            <img style="width: 20px;" :src="waitingAsset" alt="" v-else-if="index.status == 'PENDING'">
                            <img style="width: 20px;" :src="wrongAsset" alt="" v-else-if="index.status == 'CANCELED'">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br>
        <br>
        <br>
        <NavbarComponent/>
    </div>
</template>

<script>
import NavbarComponent from '../../components/v2/NavbarComponent.vue';
import checkListAsset from '../../assets/checklist.png';
import wrongAsset from '../../assets/wrong.png';
import waitingAsset from '../../assets/waiting.png';
import axios from 'axios';

export default {
    name: 'HistoryDonation',
    components: {
        NavbarComponent,
    },
    data() {
        return {
            checkListAsset,
            wrongAsset,
            waitingAsset,
            donations: []
        }
    },

    beforeMount() {
        this.getHistoryDonation();
    },

    methods: {
        goDetail(id) {
            this.$router.push('/donations/' + id)
        },

        getHistoryDonation() {
            axios.get(process.env.VUE_APP_API + 'donation', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    console.info(data);

                    data.forEach(element => {
                        this.donations.push({
                            id: element.id,
                            campaign_title: element.campaign_title,
                            campaign_img: element.campaign_img,
                            status: element.status,
                            location: element.location,
                        });
                    });
                }
            })
        }
    }
}

</script>